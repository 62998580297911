import { SITTER_PROFILE_PREFIX } from './app';

type PageLayoutConfig = {
  [key: string]: {
    footer: string[];
    header: string[];
  };
};

export const HOME_PAGE_SLUG = '/';
export const HOW_IT_WORKS_PAGE_SLUG = '/how-it-works';
export const SEARCH_RESULTS_PAGE_SLUG = '/[serviceSlug]/[locationSlug]';
export const STEPPER_SLUG = '/stepper/[stepperId]';
export const PETS_PAGE_SLUG = '/pets';
export const PET_PROFILE_VIEW_PAGE_SLUG = '/pets/[petId]/view';
export const SERVICES_AND_RATES_HOME_PAGE_SLUG = '/services-and-rates';
export const SERVICES_AND_RATES_VIEW_PAGE_SLUG = '/services-and-rates/[serviceTypeId]';
export const NOT_FOUND_PAGE_SLUG = '/404';
export const SERVICE_LANDING_PAGE_SLUG = '/pet-sitters/[servicePageId]';
export const SITTER_PROFILE_PAGE_SLUG = `/${SITTER_PROFILE_PREFIX}/[locationSlug]/[sitterProfileSlug]`;
export const ACCOUNT_PAGE_SLUG = '/account';
export const ACCOUNT_SETTINGS_PAGE_SLUG = '/account-settings';
export const PERSONAL_DETAILS_PAGE_SLUG = '/account/personal-details';
export const NOTIFICATION_SETTINGS_PAGE_SLUG = '/account/settings';
export const MAD_PAWS_CREDITS_PAGE_SLUG = '/account/mad-paws-credits';
export const MESSAGES_PAGE_SLUG = '/inbox';
export const BOOKINGS_PAGE_SLUG = '/bookings';
export const CALENDAR_PAGE_SLUG = '/calendar';
export const PAYMENT_PAGE_SLUG = '/billing-history';
export const PROFILE_PAGE_SLUG = '/profile';
export const OLD_SERVICE_AND_RATES_PAGE_SLUG = '/profile/services-and-rates';
export const PROFILE_ABOUT_PAGE_URL = '/profile/about';
export const PHOTO_GALLERY_PAGE_SLUG = '/profile/photo-gallery';
export const HOUSEHOLD_PAGE_SLUG = '/profile/household';
export const REFERRAL_LANDING_PAGE_SLUG = '/refer-a-friend';

export const MOBILE = 'mobile';
export const DESKTOP = 'desktop';

export const PAGE_LAYOUT_CONFIG: PageLayoutConfig = {
  [HOME_PAGE_SLUG]: {
    header: [MOBILE, DESKTOP],
    footer: [MOBILE, DESKTOP],
  },
  [HOW_IT_WORKS_PAGE_SLUG]: {
    header: [MOBILE, DESKTOP],
    footer: [MOBILE, DESKTOP],
  },
  [SEARCH_RESULTS_PAGE_SLUG]: {
    header: [MOBILE, DESKTOP],
    footer: [MOBILE, DESKTOP],
  },
  [PET_PROFILE_VIEW_PAGE_SLUG]: {
    header: [MOBILE, DESKTOP],
    footer: [],
  },
  [NOT_FOUND_PAGE_SLUG]: {
    header: [MOBILE, DESKTOP],
    footer: [MOBILE, DESKTOP],
  },
  [SERVICES_AND_RATES_HOME_PAGE_SLUG]: {
    header: [MOBILE, DESKTOP],
    footer: [],
  },
  [SERVICES_AND_RATES_VIEW_PAGE_SLUG]: {
    header: [MOBILE, DESKTOP],
    footer: [],
  },
  [SERVICE_LANDING_PAGE_SLUG]: {
    header: [MOBILE, DESKTOP],
    footer: [MOBILE, DESKTOP],
  },
  [SITTER_PROFILE_PAGE_SLUG]: {
    header: [DESKTOP],
    footer: [DESKTOP],
  },
  [ACCOUNT_SETTINGS_PAGE_SLUG]: {
    header: [MOBILE, DESKTOP],
    footer: [],
  },
  [PROFILE_PAGE_SLUG]: {
    header: [MOBILE, DESKTOP],
    footer: [],
  },
  [REFERRAL_LANDING_PAGE_SLUG]: {
    header: [MOBILE, DESKTOP],
    footer: [MOBILE, DESKTOP],
  },
};
