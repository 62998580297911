export const HOUSE_VISITS_HIGHLIGHTING_SPLIT_ID = 'SP-1840-house-visit-highlighting';
export const HOUSE_VISITS_HIGHLIGHTING_NEW_SPLIT_ID_VALUE = 'house-visits-highlighting';

export const AREA10_CMAX_SEO_CONTENT_ON_SEARCH_FOR_SITTER_PAGE_SPLIT_ID =
  'WEB-1909-area10-cmax-on-search-for-sitter-page';
export const AREA10_CMAX_SEO_CONTENT_ON_SITTER_PROFILE_PAGE_SPLIT_ID =
  'WEB-1909-area10-cmax-on-sitter-profile-page';
export const AREA10_CMAX_COMMON_VALUES = {
  OFF: 'off',
  ON: 'on',
};

export const TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID =
  'WEB-1731-two-factor-authentication-social-logins';
export const TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID_VALUES = {
  OFF: 'off',
  ON: 'on',
};

export const FEATURE_HOLD_OUT_SPLIT_ID = 'WEB-2144-Feature-Hold-Out';
export const FEATURE_HOLD_OUT_SPLIT_ID_ID_VALUES = {
  OFF: 'off',
  ON: 'on',
};
export const FEATURE_HOLD_OUT_SPLIT_ID_DYNAMIC_CONFIG = {
  SFS_DISPLAY_SITTER_RATINGS_AND_REVIEWS: 'sfs-display-sitter-ratings-and-reviews',
  SFS_FILTER_BY_REVIEWS: 'sfs-filter-by-reviews',
  HOME_PAGE_COMPONENT_CHANGES: 'home-page-component-changes',
};

export const SPLIT_IO_ALLOWED_TIMEOUT_TO_INIT = 5000;
export const SPLIT_IO_INTERVAL_TO_INIT = 1000;

export const HOME_PAGE_CONVERSION_TEST_SPLIT_ID = 'WEB-2173-HOME-PAGE_CONVERSION_TEST';
export const HOME_PAGE_CONVERSION_TEST_SPLIT_ID_VALUES = {
  ON: 'v1',
  OFF: 'off',
};
