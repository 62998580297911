import { LayoutPlatform, useMediumFromMediaQuery } from '@madpaws/design-system';
import { useState } from 'react';

import { FROM_IN_APP_BROWSER } from '~/common/constants/auth';
import {
  HOME_PAGE_SLUG,
  HOW_IT_WORKS_PAGE_SLUG,
  SEARCH_RESULTS_PAGE_SLUG,
  SERVICE_LANDING_PAGE_SLUG,
  SITTER_PROFILE_PAGE_SLUG,
} from '~/common/constants/pageLayout';
import { PlatformFooter } from '~/components/PlatformFooter/PlatformFooter';
import {
  ContextualLinksContext,
  ContextualLinksDispatchContext,
} from '~/components/PlatformFooter/PlatformFooterContextualLinks/context';

import { PlatformHeader } from './PlatformHeader/PlatformHeader';
import { shouldFooterBeDisplayed, shouldHeaderBeDisplayed } from './utils';

import type { ReactElement, ReactNode } from 'react';
import type { ContextualLinksState } from '~/components/PlatformFooter/PlatformFooterContextualLinks/context';

/*
 * This HTML ID-s are used to find the elements
 * and this is required to inject CSS from ReactNative app WebView
 */
const PLATFORM_HEADER_AND_SUBHEADER_HTML_ID = 'Platform_header_and_subheader';

type Props = {
  children: ReactNode;
  from?: string | string[];
  route: string;
};

// TODO (WEB-475): Refactor to use new approach without DS Layout Grid
export const PAGES_WITH_OPTIONAL_LAYOUT_GRID = [
  SEARCH_RESULTS_PAGE_SLUG,
  SERVICE_LANDING_PAGE_SLUG,
  SITTER_PROFILE_PAGE_SLUG,
];

export const PAGES_WITH_PLATFORM_SUB_HEADER = [
  HOME_PAGE_SLUG,
  SERVICE_LANDING_PAGE_SLUG,
  HOW_IT_WORKS_PAGE_SLUG,
];

export const PAGES_WITH_TOP_PADDING_DISABLED = [
  ...PAGES_WITH_PLATFORM_SUB_HEADER,
  SEARCH_RESULTS_PAGE_SLUG,
  SITTER_PROFILE_PAGE_SLUG,
];

const getPlatformHeader = (isHeaderDisplayed: boolean, from?: string | string[]): ReactElement => {
  if (!isHeaderDisplayed || from === FROM_IN_APP_BROWSER) {
    return <></>;
  }

  return <PlatformHeader />;
};

const getIsTopPaddingDisabled = (route: string): boolean =>
  PAGES_WITH_TOP_PADDING_DISABLED.includes(route);

const getLayoutPlatformHeader = (
  isHeaderDisplayed: boolean,
  from?: string | string[]
): ReactElement => (
  <div id={PLATFORM_HEADER_AND_SUBHEADER_HTML_ID}>{getPlatformHeader(isHeaderDisplayed, from)}</div>
);

export const Layout = ({ children, route, from }: Props): ReactElement => {
  const [contextualLinks, setContextualLinks] = useState<ContextualLinksState>([]);
  const isLayoutContainerOptional = PAGES_WITH_OPTIONAL_LAYOUT_GRID.includes(route) ? true : false;
  const isMediumFromViewport = useMediumFromMediaQuery();

  const isHeaderDisplayed = shouldHeaderBeDisplayed(route, isMediumFromViewport);
  const isFooterDisplayed = shouldFooterBeDisplayed(route, isMediumFromViewport);

  return (
    <LayoutPlatform
      footer={
        <ContextualLinksContext.Provider value={contextualLinks}>
          {isFooterDisplayed && <PlatformFooter />}
        </ContextualLinksContext.Provider>
      }
      header={getLayoutPlatformHeader(isHeaderDisplayed, from)}
      isLayoutContainerOptional={isLayoutContainerOptional}
      isTopPaddingDisabled={getIsTopPaddingDisabled(route)}
    >
      <ContextualLinksDispatchContext.Provider value={setContextualLinks}>
        {children}
      </ContextualLinksDispatchContext.Provider>
    </LayoutPlatform>
  );
};
