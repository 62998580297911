import {
  SERVICES_AND_RATES_HOME_PAGE_SLUG,
  HOME_PAGE_SLUG,
  SITTER_PROFILE_PAGE_SLUG,
  SERVICES_AND_RATES_VIEW_PAGE_SLUG,
  PET_PROFILE_VIEW_PAGE_SLUG,
  SERVICE_LANDING_PAGE_SLUG,
  SEARCH_RESULTS_PAGE_SLUG,
  HOW_IT_WORKS_PAGE_SLUG,
} from '~/common/constants/pageLayout';

import { trackEvent } from './analytics';
import { CLICKED_NAVIGATION_ITEM } from './constants';
import {
  SERVICES_AND_RATES_PAGE_NAME,
  HOME_PAGE_NAME,
  SITTER_PROFILE_PAGE_NAME,
  SERVICES_AND_RATES_VIEW_PAGE_NAME,
  PET_PROFILE_VIEW_PAGE_NAME,
  SERVICE_LANDING_PAGE_NAME,
  SEARCH_RESULTS_PAGE_NAME,
  HOW_IT_WORKS_PAGE_NAME,
} from '../Layout/PlatformHeader/constants';

export enum SourceType {
  HeaderPrimary = 'header_primary',
  HeaderSecondary = 'header_secondary',
  HamburgerMenuDesktop = 'hamburger_menu_desktop',
  HamburgerMenuMobile = 'hamburger_menu_mobile',
  HeaderSecondaryHomePage = 'header_secondary_homepage',
}

// Replace all substrings that are enclosed in square brackets with '[^]*'
// e.g. if pageName is '/pets/[petId]/view', the regex will be '/pets/[^]*/view'
const generatePageNameRegex = (pageName: string): RegExp =>
  new RegExp(pageName.replace(/\[.*?\]/g, '[^]*'));

export const viewPageNameResolver = (pathName: string): string => {
  if (SERVICES_AND_RATES_HOME_PAGE_SLUG === pathName) {
    return SERVICES_AND_RATES_PAGE_NAME;
  }

  if (HOME_PAGE_SLUG === pathName) {
    return HOME_PAGE_NAME;
  }

  if (HOW_IT_WORKS_PAGE_SLUG === pathName) {
    return HOW_IT_WORKS_PAGE_NAME;
  }

  if (generatePageNameRegex(SITTER_PROFILE_PAGE_SLUG).test(pathName)) {
    return SITTER_PROFILE_PAGE_NAME;
  }

  if (generatePageNameRegex(SERVICES_AND_RATES_VIEW_PAGE_SLUG).test(pathName)) {
    return SERVICES_AND_RATES_VIEW_PAGE_NAME;
  }

  if (generatePageNameRegex(PET_PROFILE_VIEW_PAGE_SLUG).test(pathName)) {
    return PET_PROFILE_VIEW_PAGE_NAME;
  }

  if (generatePageNameRegex(SERVICE_LANDING_PAGE_SLUG).test(pathName)) {
    return SERVICE_LANDING_PAGE_NAME;
  }

  if (generatePageNameRegex(SEARCH_RESULTS_PAGE_SLUG).test(pathName)) {
    return SEARCH_RESULTS_PAGE_NAME;
  }

  return pathName;
};

/*
 * This function is to track the event when a user clicks on a navigation item.
 * @param {string} link - The URL of the navigation item.
 * @param {string} name - The displayed name of the navigation item.
 * @param {SourceType} source - The source of the navigation item.
 * @param {boolean} isSitter - If the user is a sitter, false if logged out.
 */
export const handleNavigationItemClickedEvent = (
  link: string,
  name: string,
  source: SourceType,
  isSitter: boolean
): void => {
  const originalUrl = new URL(window.location.href);
  const pathName = originalUrl.pathname;

  const payload = {
    link,
    // the name of the current page
    page: viewPageNameResolver(pathName),
    name,
    source,
    is_sitter: isSitter,
  };

  trackEvent(CLICKED_NAVIGATION_ITEM, payload);
};
