import { FROM_IN_APP_BROWSER } from '~/common/constants/auth';
import {
  SITTER_PROFILE_PAGE_SLUG,
  MOBILE,
  DESKTOP,
  SEARCH_RESULTS_PAGE_SLUG,
  HOME_PAGE_SLUG,
  HOW_IT_WORKS_PAGE_SLUG,
} from '~/common/constants/pageLayout';

import { ScreenWidths } from './userAgent';

interface PagesWithHelpCentreWidget {
  [key: string]: string[];
}

export const PAGES_WITH_HELP_CENTRE_WIDGET: PagesWithHelpCentreWidget = {
  [SEARCH_RESULTS_PAGE_SLUG]: [DESKTOP, MOBILE],
  [HOME_PAGE_SLUG]: [DESKTOP, MOBILE],
  [SITTER_PROFILE_PAGE_SLUG]: [DESKTOP],
  [HOW_IT_WORKS_PAGE_SLUG]: [DESKTOP, MOBILE],
};

export const getPlatformFromWidth = (width: number): string =>
  width > ScreenWidths.SMALL ? DESKTOP : MOBILE;

export const shouldShowChatWidget = (
  inferredScreenWidth: number,
  pathname: string,
  requestOrigin?: string | string[]
): boolean => {
  const platform = getPlatformFromWidth(inferredScreenWidth);
  const supportedPlatforms = PAGES_WITH_HELP_CENTRE_WIDGET[pathname];

  if (!supportedPlatforms) {
    return false;
  }

  if (platform === MOBILE) {
    return supportedPlatforms.includes(MOBILE) && requestOrigin !== FROM_IN_APP_BROWSER;
  }

  return supportedPlatforms.includes(DESKTOP) && requestOrigin !== FROM_IN_APP_BROWSER;
};
